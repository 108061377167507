import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import {
  Layout,
  Details,
  TemplateHeading,
  Banner,
  Download,
} from "../../components"
import * as S from "./method.styles"

const MethodTemplate = ({ data, location }) => {
  const method = data.contentfulMethod
  return (
    <Layout
      title={method.title}
      description={method.metaDescription}
      image={method?.image?.fluid?.src}
    >
      <S.Grid noDownload={method.pdf}>
        <TemplateHeading
          title={method.title}
          desc={method.summary?.summary}
          parentContext={{
            parentLabel: "All Methods",
            childLabel: "Method",
            location,
          }}
        />
        {method.pdf && (
          <Download
            url={method.pdf.file.url}
            fileName={method.pdf.file.fileName}
            label="Download PDF"
            fileSize={method.pdf.file.details.size}
          />
        )}
        <Banner image={method.image} />
        <S.BorderLine />
        {method.details && <Details content={method.details} />}
        {method?.steps?.map((step, i) => {
          return (
            <>
              <S.BorderLine />
              <S.MethodContentExt
                key={i}
                index={i + 1}
                total={method.steps.length}
                title={step.title}
                text={step?.body2?.json}
                checklist={step.list}
                video={step.video}
              />
            </>
          )
        })}
      </S.Grid>
    </Layout>
  )
}

MethodTemplate.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    contentfulMethod: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default MethodTemplate

export const pageQuery = graphql`
  query MethodBySlug($slug: String!) {
    contentfulMethod(slug: { eq: $slug }) {
      id
      slug
      title
      image {
        description
        fluid(maxWidth: 1312, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      pdf {
        file {
          url
          fileName
          details {
            size
          }
        }
      }
      details {
        label
        text
      }
      summary {
        summary
      }
      metaDescription
      steps {
        title
        video
        body2 {
          json
        }
        list
      }
    }
  }
`
