import media from "styled-media-query"
import styled from "styled-components"
import {
  Details,
  TemplateHeading,
  Banner,
  MethodContent,
  Download,
} from "../../components"

export const Grid = styled.div`
  display: grid;
  grid-template-rows: auto;
  padding-top: 2.5rem;
  ${Download} {
    padding: 2rem 1rem;
  }
  ${TemplateHeading} {
    padding: ${(props) =>
      props.noDownload === null ? "0rem 1rem 2rem 1rem" : "0rem 1rem"};
  }
  ${Banner}, ${MethodContent} {
    border-top: ${(props) => props.theme.borderStyle};
  }
  ${Banner} {
    border-bottom: 1px solid black;
    height: 320px;
  }
  ${Details} {
    div {
      padding: 1.5rem 1rem;
      &:not(:last-child) {
        border-bottom: ${(props) => props.theme.borderStyle};
      }
    }
  }
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-template-rows: auto;
    grid-template-columns: 27.3% 1fr;
    padding: 4rem 0rem 3rem 0rem;
    ${TemplateHeading}, ${Download}, ${Details}, ${MethodContent} {
      grid-column: 2 / end;
    }
    ${TemplateHeading} {
      padding: ${(props) =>
        props.noDownload === null
          ? "0rem 0rem 4rem 0rem"
          : "0rem 0rem 1.5rem 0rem"};
      > *  {
        max-width: 62.5%;
      }
    }
    ${Download} {
      margin-bottom: 4rem;
      padding-left: 0rem;
    }
    ${Banner} {
      grid-column: 1 / 3;
    }
    ${Details} {
      padding: 1.5rem 0rem 2rem 0rem;
      div {
        border-bottom: none !important;
      }
    }
  `}
  ${media.between("medium", "1055px")`
     ${MethodContent}, ${TemplateHeading} {
        padding-right: 2rem;
        > * {
          max-width: 100%;
        }
      }
  `}
`
export const BorderLine = styled.b`
  ${media.greaterThan("medium")`
    grid-column: span 1;
    border-top: ${(props) => props.theme.borderStyle};
  `}
`
export const MethodContentExt = styled(MethodContent)`
  padding: 2rem 1rem 1rem 1rem;
  ${media.greaterThan("medium")`
      padding: 2rem 2rem 4rem 0rem;
  `}
`
